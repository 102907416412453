@import 'src/sass/uhc/tokens/uhc.scss';
@import 'src/sass/helpers/mixins';

.button {
    box-sizing: content-box;
    height: 40px;
    width: 40px;
    background-color: #fff;
    display: block;
    border: 1px solid $rds-color-neutral-50;
    border-radius: 50em;

    &:hover {
        background-color: #f3f3f3;
    }

    &:focus {
        background-color: #fff;
        color: #fff;
    }

    &:active {
        box-shadow: 0 0 0 4px #f3f3f3;
    }
}

.icon {
    outline: none;
    padding: 12px;
}
